<template>
  <div v-if="salesPermission">
    <div>
      <div
        v-if="items.child[0]"
        style="width: 230px; margin: -13px auto 6px;"
      >
        <chart-doughnut
          ref="salesChart"
          :chart-data="doughnutChartData"
          :is-tooltip="true"
          :is-legend="false"
          :is-plugin="true"
          :required-plugin-ids="['textInDashboardDoughnut', 'itemCategoryName']"
          :doughnut-label-css-object="salesDoughnutCssType.doughnutLabelCssObject"
          :doughnut-css-object="salesDoughnutCssType.doughnutCssObject"
        />
      </div>

      <!--도넛차트 매출액 정보-->
      <div
        v-if="items.child[0]"
        class="doughnut_cart_count"
      >
        <div class="flex estimated_sales">
          <div class="contents_title font-weight-bold">
            {{ '예상 매출액' | translate }}
          </div>
          <div class="font-weight-bold">
            {{ sales.estimatedFee | currency }}
          </div>
        </div>
        <div>
          <div class="flex actual_sales">
            <div class="sales_color_box1" />
            <div class="contents_title">
              {{ '매출액' | translate }}
            </div>
            <div>{{ sales.sumReqPrice | currency }}</div>
          </div>
          <div class="flex actual_sales">
            <div class="sales_color_box2" />
            <div class="contents_title">
              {{ '수금액' | translate }}
            </div>
            <div>{{ sales.sumPrice | currency }}</div>
          </div>
        </div>
      </div>

      <!--total count-->
      <div
        v-if="items.child[1]"
        class="total_count_wrap"
      >
        <div class="flex total_count mb-1">
          <div>{{ '미수금액' | translate }}</div>
          <div>{{ sales.sumBalance | currency }} {{ '원' | translate }}</div>
        </div>
        <div class="flex total_count">
          <div>{{ '미수금 건수' | translate }}</div>
          <div>{{ sales.balanceCount | currency }} {{ '건' | translate }}</div>
        </div>
      </div>

      <div v-if="items.child[3]">
        <div class="chart_subtitle">
          {{ '매출 추이' | translate }}
        </div>
        <div style="width: 100%; margin: 6px auto 0;">
          <chart-vertical-bar
            ref="salesBarChart"
            :chart-data="barChartData"
            :is-tooltip="true"
            :is-legend="true"
            :is-plugin="true"
            :required-plugin-ids="['legendMarginBottom', 'yAxisUnit']"
            :bar-css-object="salesBarCssType.barCssObject"
            :bar-label-css-object="salesBarCssType.barLabelCssObject"
            :bar-scales="salesBarCssType.scales"
            :step-size="stepSize"
          />
        </div>
      </div>

      <div
        v-if="items.child[2]"
        class="storage_status_wrap"
      >
        <div class="chart_subtitle mb-2">
          {{ '수납 현황' | translate }}
        </div>
        <div class="storage_status">
          <div class="total_title">
            {{ '총'|translate }} {{ depositSummary.totalCount | currency }} {{ '건' | translate }}
          </div>
          <div class="flex storage_status_right_wrap">
            <div class="storage_status_text_wrap">
              <div>CMS({{ '이체' | translate }})</div>
              <div class="storage_status_text">
                <div>
                  {{ depositSummary.cmsBankCount | currency }} {{ '건' | translate }}
                </div>
                <div>|</div>
                <div>
                  {{ depositSummary.cmsBankAmount | currency }} {{ '원' | translate }}
                </div>
              </div>
            </div>
            <div>
              <div class="storage_status_text_wrap">
                <div>CMS({{ '카드' | translate }})</div>
                <div class="storage_status_text">
                  <div>
                    {{ depositSummary.cmsCardCount | currency }} {{ '건' | translate }}
                  </div>
                  <div>|</div>
                  <div>
                    {{ depositSummary.cmsCardAmount | currency }} {{ '원' | translate }}
                  </div>
                </div>
              </div>
              <div class="storage_status_text_wrap">
                <div>{{ '기타수납' | translate }}</div>
                <div class="storage_status_text">
                  <div>
                    {{ depositSummary.etcCount | currency }} {{ '건' | translate }}
                  </div>
                  <div>|</div>
                  <div>
                    {{ depositSummary.etcAmount | currency }} {{ '원' | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <renewal-dashboard-textarea-component
        v-show="items.child[4]"
        :comment-info="comment"
        :date="date"
        name="sales"
      />
    </div>
  </div>
  <div v-else>
    <no-items
      :title="'매출 조회 권한 없음.' | translate"
    />
  </div>
</template>

<script>
    import {mapGetters, KEYS} from "@/store/modules/dashboard";
    import {DoughnutType, BarType} from "./dashboardChartCss";
    import ChartDoughnut from "@/components/chart/renewal-dashboard/ChartDoughnut";
    import ChartVerticalBar from "@/components/chart/renewal-dashboard/ChartVerticalBar";
    import RenewalDashboardTextareaComponent
        from "@/views/home/dashboard/dashboard-widget/RenewalDashboardTextareaComponent";
    import userManager from "@/assets/plugins/mps-common/user/user-manager";
    import Permissions from "@/constants/permissions";
    import NoItems from "@/components/items/NoItems.vue";

    export default {
        name: "DashboardWidgetSalesWindow",
        components: {
          NoItems,
            RenewalDashboardTextareaComponent,
            ChartDoughnut,
            ChartVerticalBar
        },
        props: {
            items: {
                type: Object,
                default: () => ({child: [true, true, true, true]})
            },
            duration: {
                type: Number,
                default: 6
            },
            date: {
                type: String,
                default: '2021-10'
            }
        },
        data() {
            return {
                dashboard: null,
                /**
                 * 각 그래프의 CSS TYPE 을 지정해주세요.
                 */
                salesDoughnutCssType: DoughnutType[1],
                salesBarCssType: BarType[3],
                stepSize: 500000,
            }
        },
        computed: {
            ...mapGetters({
                salesForSum: KEYS.GET_SALES_SUM_DATA,
                salesForTrend: KEYS.GET_SALES_LIST_DATA,
            }),
            doughnutChartData() {
                if (this.salesForSum?.graph) {
                    const salesForSum = Object.assign({}, this.salesForSum);
                    if (salesForSum?.graph) {
                        salesForSum.graph.datasets[0] = {
                            ...salesForSum.graph.datasets[0],
                            ...this.salesDoughnutCssType.doughnutDataCssObject,
                        }
                        return salesForSum?.graph || {};
                    } else {
                        return {}
                    }
                }
                return {}
            },
            barChartData() {
                if (this.salesForTrend?.graph) {
                    const salesForTrend = Object.assign({}, this.salesForTrend);
                    if (salesForTrend?.graph) {
                        salesForTrend.graph.datasets[0] = {
                            ...salesForTrend.graph.datasets[0],
                            ...this.salesBarCssType.barDataCssObject
                        }
                        salesForTrend.graph.datasets[1] = {
                            ...salesForTrend.graph.datasets[1],
                            ...this.salesBarCssType.secondBarDataCssObject
                        }
                        return salesForTrend?.graph || {};
                    } else {
                        return {}
                    }
                }
                return {}
            },
            sales(){
                return this.salesForSum || {};
            },
            depositSummary(){
                return this.salesForSum?.depositSummary || {};
            },
            comment(){
                return this.salesForSum?.comment || {};
            },
          salesPermission() {
            return userManager.hasPermission(Permissions.EXPOSE_BILLING_MANAGE)
          },
        },
        watch: {
            salesForTrend: {
                deep: true,
                handler() {
                    this.stepSize = this.salesForTrend.graph.stepSize;
                }
            }
        },
        created() {
        },
        methods: {
            setLocaleString(num) {
                return num ? Number(num).toLocaleString() : '0'
            }
        }
    }
</script>

<style scoped>
    .doughnut_cart_count {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 10px;
    }

    .estimated_sales {
        display: flex;
        font-size: 0.875rem;
        font-weight: 500;
        margin-bottom: 8px;
    }

    .actual_sales {
        display: flex;
        align-items: center;
        font-size: 0.875rem;
        margin-bottom: 6px;
    }

    .contents_title {
        margin-right: 10px;
    }

    .sales_color_box2 {
        width: 10px;
        height: 10px;
        border-radius: 2px;
        background-color: #0C98FE;
        margin-right: 8px;
    }

    .sales_color_box1 {
        width: 10px;
        height: 10px;
        border-radius: 2px;
        background-color: #cecece;
        margin-right: 8px;
    }

    .total_count_wrap {
        width: 100%;
        background-color: #f8f8f8;
        display: flex;
        flex-direction: column;
        padding: 10px;
        border-radius: 4px;
        font-size: 0.875rem;
        justify-content: space-between;
        font-weight: 500;
        margin-bottom: 20px;
    }

    /* .total_count_wrap div:first-child {
         margin-right: 8px;
     }*/

    .total_count {
        justify-content: space-between;
        font-size: 0.875rem;
    }

    .chart_subtitle {
        text-align: left;
        font-size: 0.917rem;
        font-weight: bold;
    }

    .detail_button {
        color: #0C98FE;
    }

    .storage_status_wrap {
        margin-top: 10px;
    }

    .storage_status {
        background-color: #f8f8f8;
        padding: 10px;
        display: flex;
        font-size: 0.875rem;
        flex-direction: column;
        font-weight: 500;
    }

    .storage_status > div:first-child {
        margin-bottom: 6px;
    }

    .total_title {
        font-weight: bold;
        font-size: 0.875rem;
        text-align: left;
    }

    .storage_status_right_wrap {
        display: flex;
        flex-direction: column;
    }

    .storage_status_text_wrap {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;
        width: 100%;
    }

    .storage_status_text {
        display: flex;
        width: 60%;
    }

    .storage_status_text div:nth-child(1) {
        width: 33%;
        text-align: right;
    }

    .storage_status_text div:nth-child(2) {
        width: 13%;
        color: #aaa;
    }

    .storage_status_text div:nth-child(3) {
        width: 67%;
        text-align: right;
    }

    .storage_status_text_wrap > div:first-child {
        color: #333;
    }

    /*.storage_status_text div:first-child::after {
        !*content: "|";*!
        content: url("/img/dashboard/vertical_line.svg");
        margin: 0 14px;
        color: #B2B2B2;
    }*/

</style>